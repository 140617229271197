import React, { useState, useEffect } from 'react';
import { getDatabases, uploadDatabaseFile } from '../services/databaseService';
import { listHistory } from '../services/historyService';
import { useRef } from 'react';

const FormComponent = ({
    onFormSubmit,
    loadingFormSubmit,
    clientId,
    dataPlanning,
    onGetPlanning,
    loadingDataPlanning
}) => {

    const [databaseOptions, setDatabaseOptions] = useState([]);
    const [history, setHistory] = useState([]);
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentItem, setCurrentItem] = useState(false); 
    const itemsPerPage = 5;
    const timeout = useRef(null);

    const [formData, setFormData] = useState({
        database: '',
        start_date: '2024-01-01',
        end_date: '2024-01-07',
        sunday: 0,
        monday: 480,
        tuesday: 480,
        wednesday: 480,
        thursday: 480,
        friday: 480,
        saturday: 260,
        radius_limit_in_meters: 40,
        percentage_route: 30,
        cache: false,
        frequency_of_occurrence: 'category',
        input_frequency_of_occurrence: 0,
        type_frequency_of_occurrence: "week",
        users: "find_out",
        input_users: 0,
        duration_in_task: 'custom',
        input_duration_in_task: 0
    });

    useEffect(() => {
        fetchDatabases();
        fetchHistory();
    }, []);

    useEffect(() => {
        if (!loadingFormSubmit) {
            fetchHistory();
            controlFetchHistory()
        }
    }, [loadingFormSubmit]);

    const handleChange = (e) => {
        const { name, type } = e.target;
        const value = type === 'checkbox' ? e.target.checked : (type == "number" || type == "range" ? parseInt(e.target.value) : e.target.value);

        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
    };

    const fetchDatabases = async () => {
        try {
            const databases = await getDatabases({
                client_id: clientId
            });
            setDatabaseOptions(databases);
        } catch (error) {
            console.log(error)
        }
    };

    const fetchHistory = async() => {
        try {
            const history = await listHistory({
                client_id: clientId
            });
            setHistory(history);
            return history;
        } catch (error) {
            console.log(error)
        }
    }

    const controlFetchHistory = async() =>{
        clearTimeout(timeout.current);
        timeout.current = setTimeout(async () => {
            const history = await fetchHistory();
            if (history && history.find(v => v.status == "in_progress")) {
                controlFetchHistory();
            }
        }, 5000);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData['database']) {
            alert("Selecione uma Base para buscar");
            return;
        }

        let data = {
            database: formData['database'],
            start_date: formData['start_date'],
            end_date: formData['end_date' ],
            daily_workload: { 
                monday: formData['monday'],
                tuesday: formData['tuesday'],
                wednesday: formData['wednesday'],
                thursday: formData['thursday'],
                friday: formData['friday'],
                saturday: formData['saturday'],
                sunday: formData['sunday']
            },
            radius_limit_in_meters: formData['radius_limit_in_meters'] * 1000,
            force_nocache: formData['cache'] ? 1 : 0
        };

        if (formData['percentage_route']) data['percentage_route'] = formData['percentage_route'];

        if (formData['frequency_of_occurrence'] == "all") {
            data["frequency_of_occurrence"] = {
                "type" : formData['type_frequency_of_occurrence'],
                "count": formData['input_frequency_of_occurrence']
            };
        }

        if (formData['input_duration_in_task']) data["task_duration_in_minutes"] = formData['input_duration_in_task'];
        if (formData['input_users']) data["total_users"] = formData['input_users'];

        onFormSubmit(data);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async (e) => {
        e.preventDefault();

        if (!file) {
          alert('Selecione um arquivo para fazer upload.');
          return;
        }

        setIsLoading(true);

        try {
            await uploadDatabaseFile({
                client_id: clientId,
                file
            })
            await fetchDatabases();
            setFormData(prevState => ({
                ...prevState,
                ['database']: file.name
            }));
            setFile(false);
        } catch(err) {
            try {
                const { errMsg } = err.response.data;
                if (errMsg.match(/columns in the CSV file/)) alert('Seu arquivo CSV não está com todas as colunas obrigatórias! Revise o arquivo e envie novamente.');
            } catch(e) {
                alert('Não foi possível fazer upload! Tente novamente dentro de alguns minuto.');
            }
        }

        setIsLoading(false);
    }

    const numberToLetters = (number) => {
        let letters = '';

        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

        while (number > 0) {
            const indice = (number - 1) % 26;
            letters = alphabet[indice] + letters;
            number = Math.floor((number - 1) / 26);
        }

        return letters;
    }

    const download = function (data) {

        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)

        const a = document.createElement('a')

        a.setAttribute('href', url)

        a.setAttribute('download', `${currentItem.database}`);

        a.click()
    }

    const csvmaker = function ({
        users,
        poisWithoutProcessed
    }) {

        const csvRows = [];

        const headers = ['id_local, nome_fantasia, razao_social, ordem_visita, data da visita, promotor, cluster_id, latitude, longitude, cnpj_cpf'];

        csvRows.push(headers.join(','));

        for (const date in users) {
            if (!users[date].length) continue;

            for (const item of users[date]) {
                if (!item.routes) continue;

                for (const [index, route] of item.routes.entries()) {
                    if (!route.id) continue;

                    const obj =`${route.id},${route.name},${route.company_name},${index},${date},${item.cluster}:${numberToLetters(item.id)},${item.cluster},${route.latitude},${route.longitude},${route.cnpj}`;
                    csvRows.push(obj);
                }
            }
        }

        if (poisWithoutProcessed && poisWithoutProcessed.length) {
            Array.from(Array(10).keys()).map(v => csvRows.push(',,,,,'));
            const headersPois = ['id_local, nome_fantasia, razao_social, latitude, longitude, cnpj_cpf'];
            csvRows.push(headersPois.join(','));
            for (let poi of poisWithoutProcessed) {
                const obj =`${poi.id},${poi.name},${poi.company_name},${poi.latitude},${poi.longitude},${poi.cnpj}`;
                csvRows.push(obj);
            }
        }
        
        return csvRows.join('\n')
    }


    const handleDownload = (e) => {
        e.preventDefault();

        const { users, cluster, pois_without_processed: poisWithoutProcessed } = dataPlanning;
        const csvdata = csvmaker({users, cluster, poisWithoutProcessed});
        download(csvdata);
    }

    const handleChangeRadio = (e) => {
        const { name, value } = e.target;

        if (name == "frequency_of_occurrence" && value == "category") resetValues({input_frequency_of_occurrence: 0, type_frequency_of_occurrence: "week"});
        if (name == "users" && value == "find_out") resetValues({input_users: 0});
        if (name == "duration_in_task" && value == "custom") resetValues({input_duration_in_task: 0});

        handleChange(e);
    }

    const resetValues = (data) => {
        setFormData(prevState => ({
            ...prevState,
            ...data
          }));
    }

    const translateStatus = (status) => {
        const aux = {
            'in_progress': {text: 'Em progresso', color: 'orange'},
            'completed': {text: 'Concluído', color: 'green'},
            'failed': {text: 'Erro', color: 'red'},
        }
        const item = aux[status];
        return (
            <span style={{"color": item.color}}>
                {item.text}
            </span>
        );
    }
    
    const PaginatedTable = ({
        data,
        itemsPerPage
    }) => {

        const [currentPage, setCurrentPage] = useState(1);
        const maxPage = Math.ceil(data.length / itemsPerPage);

        function changePage(page, e) {
            setCurrentPage(page);
            e.preventDefault();
            return false;
        }

        const startIndex = (currentPage - 1) * itemsPerPage;
        const selectedData = data.slice(startIndex, startIndex + itemsPerPage);

        return (
            <>
                <table className="table mt-2 ml-2">
                    <thead>
                        <tr style={{fontSize: "12px"}}>
                            <th>Data</th>
                            <th>Base</th>
                            <th>Data inicial</th>
                            <th>Data final</th>
                            <th>Raio</th>
                            <th>Porcentagem</th>
                            <th>Status</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedData.map((item, index) => (
                            <tr style={{fontSize: "12px", backgroundColor: "#eee"}} key={index}>
                                <td>{item.date}</td>
                                <td>{item.database}</td>
                                <td>{item.start_date}</td>
                                <td>{item.end_date}</td>
                                <td>{item.radius_limit_in_meters / 1000} km</td>
                                <td>{item.percentage_route}%</td>
                                <td>{translateStatus(item.status)}</td>
                                <td>{item.status == "completed" && <a onClick={() => {
                                    setCurrentItem(item);
                                    onGetPlanning(item.key)
                                }} style={{color: "blue", cursor: "pointer"}}>Visualizar »</a>}
                                    {loadingDataPlanning == item.key && <div class="spinner-border ml-2 spinner-border-sm text-primary" role="status"></div>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <nav>
                    <ul className="pagination">
                        {[...Array(maxPage).keys()].map(number => (
                            <li key={number} style={{fontSize: "13px"}} className={`ml-2 page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                                <a className="page-link" href="#" onClick={(e) => changePage(number + 1, e)}>
                                    {number + 1}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </>
        );
    }

    return (
        <form class="bg-light p-3" onSubmit={handleSubmit}>
            <div class="row">
                <div class="col">

                    <div class="">
                        <h4>Base</h4>
                        <a href="https://docs.google.com/spreadsheets/d/1IAo4QaTUz5y2v34r3piLAvppXBtf5iXDXEKKxB1I120" target="_blank">Baixar modelo do csv</a>
                    </div>
                    <div class="form-row mt-3 mb-3">
                        <div class="col-4">
                            <select class="form-control" name="database" value={formData.database} onChange={handleChange}>
                                <option value="">Selecione uma base</option>
                                {databaseOptions.map(db => (
                                    <option key={db} value={db}>{db}</option>
                                ))}
                            </select>
                        </div>
                        <div class="ml-2 col-5 form-row custom-file">
                            <input type="file" accept=".csv" class="custom-file-input" id="customFile" onChange={handleFileChange} />
                            <label class="custom-file-label nowrap" for="customFile">{file ? file.name : "Selecione o arquivo"}</label>
                        </div>
                        <div class="ml-2 col-2">
                            <button id="submit" type="submit" class="btn btn-outline-primary mb-2" disabled={!!isLoading} onClick={handleUpload}>{isLoading ? "Carregando..." : "Enviar"}</button>
                        </div>
                    </div>

                    <h4>Período</h4>
                    <div class="form-row mt-3 mb-3">
                        <div class="col-4">
                            <label for="start_date">Inicio</label>
                            <input type="date" name="start_date" value={formData.start_date} onChange={handleChange} required class="form-control" placeholder="0" />
                        </div>
                        <div class="col-4">
                            <label for="end_date">Fim</label>
                            <input type="date" name="end_date" value={formData.end_date} onChange={handleChange} required class="form-control" placeholder="0" />
                        </div>
                    </div>

                    <h4>Frequência das Visitas</h4>
                    <div class="form-check mt-3">
                        <input class="form-check-input" value="category" onChange={handleChangeRadio} type="radio" name="frequency_of_occurrence" checked={formData.frequency_of_occurrence === 'category'} />
                        <label class="form-check-label">
                        Usar Categoria do Local como frequência
                        </label>
                    </div>
                    <div class="form-check mt-2">
                        <input class="form-check-input" value="all" onChange={handleChangeRadio} type="radio" name="frequency_of_occurrence" checked={formData.frequency_of_occurrence === 'all'} />
                        <label class="form-check-label">
                        Definir uma frequência única para todas as Visitas
                        </label>
                    </div>
                    <div class="form-row mt-2 mb-3">
                        <div class="col-2">
                            <input type="number" class="form-control" name="input_frequency_of_occurrence"  onChange={handleChange} value={formData.input_frequency_of_occurrence} disabled={formData.frequency_of_occurrence === 'category'} placeholder="0" />
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="week" onChange={handleChangeRadio} name="type_frequency_of_occurrence" checked={formData.type_frequency_of_occurrence === 'week'} disabled={formData.frequency_of_occurrence === 'category'} />
                            <label class="form-check-label">Por semana</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="month" onChange={handleChangeRadio} name="type_frequency_of_occurrence" checked={formData.type_frequency_of_occurrence === 'month'} disabled={formData.frequency_of_occurrence === 'category'} />
                            <label class="form-check-label">Por mês</label>
                        </div>
                    </div>

                    <h4>Usuários</h4>
                    <div class="form-check mt-3">
                        <input class="form-check-input" type="radio" name="users" onChange={handleChangeRadio} value="find_out" checked={formData.users === 'find_out'} />
                        <label class="form-check-label">
                        Deixar o Roteirizador sugerir a Quantidade de Usuários
                        </label>
                    </div>
                    <div class="form-check mt-2">
                        <input class="form-check-input" type="radio" name="users" onChange={handleChangeRadio} value="set" checked={formData.users === 'set'} />
                        <label class="form-check-label">
                        Definir a quantidade de Usuários
                        </label>
                    </div>
                    <div class="form-row mt-2 mb-3">
                        <div class="col-2">
                            <input type="number" class="form-control" value={formData.input_users}  name="input_users" onChange={handleChange} disabled={formData.users === 'find_out'} placeholder="0" />
                        </div>
                    </div>

                    <h4>Cache</h4>
                    <div class="form-check mt-3">
                        <input class="form-check-input" onChange={handleChange} type="checkbox" name="cache" />
                        <label class="form-check-label">
                        Forçar a limpeza de cache
                        </label>
                    </div>
                </div>

                <div class="col">

                    <h4>Duração das Visitas</h4>
                    <div class="form-check mt-3">
                        <input class="form-check-input" onChange={handleChangeRadio} type="radio" name="duration_in_task" value="custom" checked={formData.duration_in_task === 'custom'} />
                        <label class="form-check-label">
                        Usar o atributo do Local como Duração da Visita
                        </label>
                    </div>
                    <div class="form-check mt-2">
                        <input class="form-check-input" type="radio" onChange={handleChangeRadio} name="duration_in_task" value="set" checked={formData.duration_in_task === 'set'}/>
                        <label class="form-check-label">
                        Definir uma Duração Única para todas as Visitas
                        </label>
                    </div>
                    <div class="form-row mt-2 mb-3">
                        <div class="col-2">
                            <input type="number" class="form-control" onChange={handleChange} value={formData.input_duration_in_task} name="input_duration_in_task" disabled={formData.duration_in_task === 'custom'} placeholder="0" />
                        </div>
                        <div class="col-2">
                            <label class="form-check-label mt-2">
                                Minutos
                            </label>
                        </div>
                    </div>

                    <h4>Carga Horária Útil Diária (minutos)</h4>
                    <div class="form-row mt-3 mb-3">
                        <div class="col">
                            <label for="inputEmail4">Seg</label>
                            <input type="number" class="form-control" name="monday" onChange={handleChange} value={formData.monday} />
                        </div>
                        <div class="col">
                            <label for="inputEmail4">Ter</label>
                            <input type="number" class="form-control" name="tuesday" onChange={handleChange} value={formData.tuesday} />
                        </div>
                        <div class="col">
                            <label for="inputEmail4">Qua</label>
                            <input type="number" class="form-control" name="wednesday" onChange={handleChange} value={formData.wednesday} />
                        </div>
                        <div class="col">
                            <label for="inputEmail4">Qui</label>
                            <input type="number" class="form-control" name="thursday" onChange={handleChange} value={formData.thursday} />
                        </div>
                        <div class="col">
                            <label for="inputEmail4">Sex</label>
                            <input type="number" class="form-control" name="friday" onChange={handleChange} value={formData.friday} />
                        </div>
                        <div class="col">
                            <label for="inputEmail4">Sab</label>
                            <input type="number" class="form-control" name="saturday" onChange={handleChange} value={formData.saturday} />
                        </div>
                        <div class="col">
                            <label for="inputEmail4">Dom</label>
                            <input type="number" class="form-control" name="sunday" onChange={handleChange} value={formData.sunday} disabled/>
                        </div>
                    </div>

                    <h4>Raio</h4>
                    <div class="form-row mt-3 mb-5">
                        <div class="col-6">
                            <label></label>
                            <input type="range" min="1" max="200" value={formData.radius_limit_in_meters} class="form-control-range" onChange={handleChange} name="radius_limit_in_meters" />
                        </div>
                        <div class="col-2 mt-2">
                            <input type="text" class="form-control" id="radius-input" placeholder={`${formData.radius_limit_in_meters} km`}  />
                        </div>
                    </div>

                    <h4>Porcentagem de cada rota</h4>
                    <div class="form-row mt-3 mb-3">
                        <div class="col-2">
                            <input type="number" required class="form-control" onChange={handleChange} name="percentage_route" min="1" max="100" value={formData.percentage_route} />
                        </div>
                    </div>

                    <div class="container-csv form-row mt-5 justify-content-between">
                        <button id="submit" type="submit" class="btn btn-primary mb-2" disabled={loadingFormSubmit}>{loadingFormSubmit ? "Carregando" :"Gerar Roteirização"}</button>
                        {dataPlanning && <button id="csv" type="submit" onClick={handleDownload} class="btn btn-outline-primary mb-2 mr-5">Download CSV</button>}
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col">
                    <h5>Histórico dos últimos 30 dias</h5>
                </div>
                {history && history.length && <PaginatedTable data={history} itemsPerPage={itemsPerPage} />}
            </div>
        </form>
    );
}

export default FormComponent;