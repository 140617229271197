import axios from 'axios';

const API_BASE_URL = "/api/history";

const listHistory = async ({
    client_id
}) => {
    try {
        const response = await axios.get(`${API_BASE_URL}?client_id=${client_id}`);
        return response.data;
    } catch (error) {
        console.error('Erro ao criar um novo planejamento', error);
        throw error;
    }
};

export {  listHistory };