import axios from 'axios';

const API_BASE_URL = "/api/planning";

const createPlanning = async ({
    client_id,
    data
}) => {
    try {
        const response = await axios.post(API_BASE_URL, {...data, client_id});
        return response.data;
    } catch (error) {
        console.error('Erro ao criar um novo planejamento', error);
        throw error;
    }
};

const getPlanning = async({
    client_id,
    key
}) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/${key}?client_id=${client_id}`);
        return response.data;
    } catch (error) {
        console.error('Erro ao criar um novo planejamento', error);
        throw error;
    }
}

export {  createPlanning, getPlanning };