import React, { useState, useEffect } from 'react';
import FormComponent from './FormComponent';
import MapComponent from './MapComponent';
import AlertComponent from './AlertComponent';
import { createPlanning, getPlanning } from '../services/planningService';

const ProjectPage = ({
    projectTitle,
    clientId
}) => {
    
    const [loadingFormSubmit, seLoadingFormSubmit] = useState(false);
    const [dataPlanning, setDataPlanning] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [textAlert, setTextAlert] = useState("");
    const [loadingDataPlanning, setLoadingDataPlanning] = useState(false);

    const handleFormSubmit = async (data) => {
        seLoadingFormSubmit(true);

        try {
            await createPlanning({
                data,
                client_id: clientId
            });
        } catch(e) {
            console.error(e)
        }

        seLoadingFormSubmit(false);
        setTextAlert("A roteirização está sendo gerada e aparecerá em Histórico. O processamento pode levar até 10 minutos.");
        setShowAlert(true);
    }

    const onGetPlanning = async (key) => {
        try {
            setLoadingDataPlanning(key);

            const data = await getPlanning({
                key,
                client_id: clientId
            });

            setLoadingDataPlanning(false);
            setDataPlanning(data);
        } catch(e) {
            console.error(e);
        }
    }

    return (
        <div class="m-3">
            <div className="container-fluid">
                <h1 className="text-primary">{projectTitle}</h1>

                <FormComponent
                    dataPlanning={dataPlanning}
                    clientId={clientId}
                    loadingFormSubmit={loadingFormSubmit}
                    onGetPlanning={onGetPlanning}
                    loadingDataPlanning={loadingDataPlanning}
                    onFormSubmit={handleFormSubmit} />

                <MapComponent
                    dataPlanning={dataPlanning}/>

                <AlertComponent 
                    show={showAlert} 
                    setShow={setShowAlert} 
                    message={textAlert}
                    className="alert-fixed-bottom-right"
                    variant="success"/>
            </div>
        </div>
    );
};

export default ProjectPage;