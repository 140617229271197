import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProjectPage from './components/ProjectPage';
import './App.css';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/contele/projeto_adilis" element={
                    <ProjectPage projectTitle="Adilis | Contele - Roterização e Dimensionamento" clientId="fbcb70fc-a7bf-4e8f-a907-e30375c371dc" />
                }/>
            </Routes>
        </Router>
    );
};

export default App;