import axios from 'axios';

const API_BASE_URL = "/api/database";

const getDatabases = async ({
    client_id
}) => {
    try {
        const response = await axios.get(`${API_BASE_URL}?client_id=${client_id}`);
        return response.data;
    } catch (error) {
        throw error;  
    }
};

const uploadDatabaseFile = async ({
    client_id,
    file
}) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('client_id', client_id);

        const response = await axios.post(API_BASE_URL, formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao fazer upload do arquivo:', error);
        throw error;
    }
};

export { getDatabases, uploadDatabaseFile };
