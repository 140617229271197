import React, { useState, useRef, useEffect } from 'react';

import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';

import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-routing-machine';

const MapComponent = ({
    dataPlanning
}) => {
    
    const [ currentUser, setCurrentUser ] = useState(1);
    const [ currentCluster, setCurrentCluster ] = useState(false);
    const [ infoAboutRoute, setInfoAboutRoute ] = useState(false);
    const [ currentDays, setCurrentDays ] = useState([]);
    const [ receivedDataPlanning, setReceivedDataPlanning ] = useState(false);
    const mapRef = useRef();
    const mapColors = useRef();
    const mapLayers = useRef();

    useEffect(() => {
        setReceivedDataPlanning(true);
        setCurrentCluster(false);
    }, [dataPlanning]);

    const MarkerUpdater = () => {

        const map = useMap();
        mapRef.current = map;
    
        useEffect(() => {

            if (!dataPlanning || !dataPlanning.cluster || !receivedDataPlanning || currentCluster) return;

            const { cluster: dataCluster } = dataPlanning;
            let newMarkers = []
        
            for (const cluster of dataCluster) {
                for (const point of cluster.points) {
    
                    const icon = L.divIcon({
                        className: 'custom-div-icon',
                        html: `<div style='background-color:${cluster.color};' class='marker-pin'></div><span class=cluster-title>${cluster.id}</span>`,
                        iconSize: [30, 42],
                        iconAnchor: [15, 42]
                    });
                        
                    const marker = L.marker([point.lat, point.lng], { icon }).on('click', () => {
                        
                        onClusterMarkerClick(cluster, map);
                        
                    }).addTo(map);
                    newMarkers.push(marker);
                }
            }
    
            setReceivedDataPlanning(false);
            setCurrentCluster(false);
            setCurrentDays([]);
            setInfoAboutRoute(false);
            clearLayers();

        }, [dataPlanning, map]);

        return null;
    };
    
    const onClusterMarkerClick = (cluster, map) => {

        map.eachLayer((layer) => {
            if(layer['_latlng']!=undefined || (layer['_latlngs']!=undefined))
                layer.remove();
        });

        setCurrentUser(1);
        setCurrentCluster(cluster.id);
        setCurrentDays([]);
        setInfoAboutRoute(false);

    }

    const numberToLetters = (number) => {
        let letters = '';

        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

        while (number > 0) {
            const indice = (number - 1) % 26;
            letters = alphabet[indice] + letters;
            number = Math.floor((number - 1) / 26);
        }

        return letters;
    }

    const generateColor = () => {
        const hexArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F'];
        let code = "";
        for(let i=0; i<6; i++){
            code += hexArray[Math.floor(Math.random()*16)];
        }
        return `#${code}`
    }
    
    const getDayFromDate = (date) => {
        const days = ['seg', 'ter', 'qua', 'qui', 'sex', 'sab', 'dom'];
        const d = new Date(date);
        return days[d.getDay()];
    }

    const formatTime=function (t){
        return (new Date(t%86400*1000)).toUTCString().replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, "$1h $2m $3s");
    }

    const onClickItemDay = (color, user, index) => {

        const { users: dataUsers } = dataPlanning;
        const date = user;

        let newValues = [];
        let is_active = false;
        if (currentDays.includes(index)) {
            newValues = currentDays.filter(v => v != index)
            if (!newValues.length) return;
            is_active = true;
        } else {
            newValues = [...new Set([...currentDays, index])]
        }
        setCurrentDays(newValues)

        const data = dataUsers[user].filter(v => v.cluster == currentCluster);

        if (is_active) {
            Object.keys(mapLayers.current[date]).map(v => {
                if (mapLayers.current[date][v]) {
                    mapLayers.current[date][v].map(item => {
                        mapRef.current.removeLayer(item);
                    })
                }
            })
            const newMapLayers = {}
            newMapLayers[date] = false;
            mapLayers.current = {...mapLayers.current || {}, ...newMapLayers};
            return;
        }

        setTimeout(function() {
            showRouting(date, data, currentUser, color)
        }, 500);

        setInfoAboutRoute(false);
    }

    const onClickRoute = (date, route, color, poi) => {

        const { users: dataUsers } = dataPlanning;
        const filterRoute = dataUsers[date].filter(v => v.cluster == currentCluster)[route-1];
        
        setInfoAboutRoute({user: filterRoute, date, color, poi})
    }

    const showRouting = (date, users, route, color) => {

        const data = JSON.parse(JSON.stringify(users));

        let newMapLayers = mapLayers.current || {};
        if (!newMapLayers[date]) newMapLayers[date] = {};
        if (!newMapLayers[date][route]) {
            newMapLayers[date][route] = [];
        }

        let id = 0;

        data.map(item => {
            id++;

            if (item.id == route) {
                let count = 0;
                item.routes.shift();
                item.routes.map(v => {
                    count++;
                    const icon = L.divIcon({
                        className: 'custom-div-icon',
                        html: `<div style='background-color:${color};' class='marker-pin'></div><span class=cluster-title>${numberToLetters(id)}${count}</span>`,
                        iconSize: [30, 42],
                        iconAnchor: [15, 42]
                    });
                    v.route_id = count;
                    newMapLayers[date][item.id].push(
                        L.marker([v.latitude, v.longitude], { icon }).on('click', () => {
                            onClickRoute(date, item.id, color, v)
                        }).addTo(mapRef.current)
                    );
                })
            }  
        })

        mapLayers.current = newMapLayers;
    }

    const clearLayers = () => {
        if (mapLayers.current && Object.keys(mapLayers.current).length) {
            Object.keys(mapLayers.current).map(date => {
                Object.keys(mapLayers.current[date]).map(v => {
                    if (mapLayers.current[date][v]) {
                        mapLayers.current[date][v].map(item => {
                            mapRef.current.removeLayer(item);
                        })
                    }
                })
            })
        }
    }

    const onClickUser = (user_id) => {

        const is_active = currentUser == user_id;
        if (is_active) return;

        setCurrentUser(user_id);

        clearLayers();

        setCurrentDays([]);
    }

    const renderListDays = () => {

        if (!dataPlanning || !dataPlanning.users) return null;
        const { users: dataUsers } = dataPlanning;

        return (
            <>
                {Object.keys(dataUsers).map((user, index) => {
                    if (dataUsers[user][0]) {
                        const data = dataUsers[user].filter(v => v.cluster === currentCluster);
                        if (!data || (data && !data.length)) return null;

                        let color;
                        if (mapColors.current && mapColors.current[user]) {
                            color = mapColors.current[user];
                        } else {
                            color = generateColor();
                            const obj = {};
                            obj[user] = color;
                            mapColors.current = {...mapColors.current || {}, ...obj};
                        }

                        return (
                            <li
                                key={index}
                                onClick={() => { onClickItemDay(color, user, index) }}
                                class={`item-day ${(currentDays.includes(index) ? "active" : "")}`}>
                                <b style={{ display: 'block' }}>{getDayFromDate(user)}</b>
                                <span className="cluster-color" style={{ backgroundColor: color }}></span>
                                <p>{user}</p>
                                <p>{formatTime(data[0].limit_working_time * 60)}</p>
                            </li>
                        );
                    } else {
                        return null;
                    }
                })}
          </>
        )
    }

    const renderInfoRoutes = () => {
        if (!dataPlanning || !dataPlanning.users) return null;
        const { users: dataUsers } = dataPlanning;

        let current_date;
        let count = 0;
        for (const date in dataUsers) {
            if (dataUsers[date].filter(v => v.cluster === currentCluster).length > count) {
                count = dataUsers[date].filter(v => v.cluster === currentCluster).length
                current_date = date;
            }
        }

        return (
            <>
                {dataUsers[current_date].map(user => {
                    if (user.cluster !== currentCluster) return null;
                    return (
                        <p class={`card-text ${currentUser === user.id ? 'active' : ''}`} onClick={() => {
                            onClickUser(user.id);
                        }} data-route={user.id}><b>Promotor {numberToLetters(user.id)}</b></p>
                    );
                })}
            </>
        )
    }

    const renderInfoAboutRoute = () => {
        const { user, date, color, poi } = infoAboutRoute;

        return (
            <>
                <li class="list-group-item" data-id={user.id} data-date={date}>
                    <div class="card">
                        <div class="card-header" style={{backgroundColor: color, color: "white"}}>
                            Promotor {numberToLetters(user.id)}    
                        </div>
                        <div class="card-body">
                            <p class="card-text"><b>Local selecionado</b><br/> {poi.route_id}</p>
                            <p class="card-text"><b>Nome local selecionado</b><br/> {poi.name}</p>
                            <p class="card-text"><b>Carga horária</b><br/> {formatTime(user.limit_working_time * 60)}</p>
                            <p class="card-text"><b>Qtd de locais</b><br/>{user.aux_total_pois}</p>
                            <p class="card-text"><b>Tempo em deslocamento</b><br/>{formatTime(user.aux_route_duration * 60)}</p>
                            <p class="card-text"><b>Tempo nos locais</b><br/>{formatTime(user.aux_task_duration * 60)}</p>
                            <p class="card-text"><b>Tempo total de trabalho</b><br/>{formatTime(user.working_time * 60)}</p>
                            <p class="card-text"><b>Cidades</b><br/>{user.cities.join(",")}</p>
                        </div>
                    </div>
                </li>
            </>
        )
    }

    const renderCardInfo = () => {

        const { cluster, total_tasks, total_pois } = dataPlanning;

        return (
            <>
                <p class="card-text">
                    Qtd Locais: <b>{total_pois}</b>     |
                    Qtd de visitas: <b>{total_tasks}</b>
                </p>
                <p>
                    {cluster.map(v => {
                        return (
                            <p class="cluster-link" onClick={() => onClusterMarkerClick(v, mapRef.current)}>
                                <span class="cluster-color" style={{backgroundColor: v.color}}></span>
                                <b>{v.id}</b> - <b>{v.title} </b>
                                {v.count_pois} {v.count_pois > 1 ? 'locais' : 'local'} | {v.total_users} {v.total_users > 1 ? 'promotores' : 'promotor'}
                                <br/>
                            </p>
                        )
                    })}
                </p>
            </>
        );
    }


    return (
        <>
            <div className="card mt-3 mb-3">
                <div className="card-info card-body">
                    {dataPlanning && renderCardInfo()}
                </div>
            </div>
            <div className="row-map row bg-light mt-3 m-0">
                <ul class="list-days">
                    {currentCluster && renderListDays()}
                </ul>
                <div class="col info right">
                    <div class={`row info-list ${infoAboutRoute ? 'show' :''}`}>
                        {infoAboutRoute && renderInfoAboutRoute()}
                    </div>
                </div>
                <div class="col info left">
                    <div class={`row info-list ${currentCluster ? 'show' :''}`}>
                        {currentCluster && renderInfoRoutes()}
                    </div>
                </div>
                <MapContainer
                    center={[-13.216506168162981, -53.355854815571725]}
                    zoom={5}
                    attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'}
                    style={{ height: '100%', width: '100%' }}
                    whenCreated={mapInstance => (mapRef.current = mapInstance)}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MarkerUpdater />
                </MapContainer>
            </div>
        </>
    );
};

export default MapComponent;