import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';

const AlertComponent = ({ show, setShow, message, variant, className }) => {

    useEffect(() => {
        let timer;
        if (show) {
            timer = setTimeout(() => {
                setShow(false);
            }, 10000);
        }

        return () => timer && clearTimeout(timer);
    }, [show, setShow]);

    return (
        <>
            {show && (
                <Alert className={className} variant={variant}>
                    {message}
                </Alert>
            )}
        </>
    );
};

export default AlertComponent
